import {Route, Routes, useLocation} from 'react-router-dom';
import React, {useEffect} from 'react';
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";

function App() {

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route path="/" element={<Home/>}>
                <Route path="/home" element={<Home />} />
                {/*<Route index element={<HomeStyle5 />} />*/}
            </Route>
            <Route path="*" element={<ErrorPage/>}/>
        </Routes>
    );
}

export default App;
