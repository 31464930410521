import React from 'react';
import Navbar from "../components/Navbar";
import {ReactComponent as Hero} from "../assests/svgs/hero.svg";
import {ReactComponent as Doctors} from "../assests/svgs/doctors.svg";
import {ReactComponent as Doctor} from "../assests/svgs/doctor.svg";
import {Headset} from "@phosphor-icons/react";
import Logo from "../assests/svgs/logo.svg";
// import People from "../assests/svgs/doctors.svg";

const Home = () => {
    return (
        <div>
            <Navbar/>
            <div className="relative bg-white pt-[120px] dark:bg-dark lg:pt-[150px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4 lg:w-5/12">
                            <div className="hero-content">
                                <span className="block mb-4 text-base font-medium text-body-color">
                                  Providing care can be simpler
                                </span>
                                <h1 className="mb-5 text-4xl font-bold !leading-[1.208] text-dark dark:text-white sm:text-[42px] lg:text-[40px] xl:text-5xl">
                                    Better Patient Care
                                    Starts Here
                                </h1>
                                <p className="mb-8 max-w-[480px] text-base text-body-color dark:text-dark-6">
                                    Empower your healthcare practice with Essence Health’s comprehensive EHR and
                                    practice management solutions.
                                </p>
                                <ul className="flex flex-wrap items-center">
                                    <li>
                                        <a
                                            href="/#"
                                            className="inline-flex items-center justify-center rounded-md bg-primary px-6 py-3 text-center text-base font-medium text-white hover:bg-blue-dark lg:px-7"
                                        >
                                            Schedule a Demo
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#"
                                           className="inline-flex items-center justify-center px-5 py-3 text-base ">
                                              <span className="mr-3">
                                                <Headset size={22}/>
                                              </span>
                                            <div
                                                className={'text-left flex flex-col font-medium text-[#464646] hover:text-primary dark:text-white'}>
                                                24/7 Support
                                                <small className={'font-normal mt-[-5px]'}>We are available when you
                                                    want</small>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="hidden px-4 lg:block lg:w-1/12"></div>
                        <div className="w-full px-4 lg:w-6/12">
                            <div className="lg:ml-auto lg:text-right">
                                <div className="relative z-10 inline-block pt-11 lg:pt-0 mt-[-64px]">
                                    {/*<img*/}
                                    {/*    src="https://cdn.tailgrids.com/1.0/assets/images/hero/hero-image-01.png"*/}
                                    {/*    alt="hero"*/}
                                    {/*    className="max-w-full lg:ml-auto"*/}
                                    {/*/>*/}
                                    <Hero className="max-w-full lg:ml-auto"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={' text-center mb-4 w-full mt-5'}>
                        <div className={'inline-flex gap-8 text-[14px] font-medium text-body-color'}>
                            <span className="block text-body-color">
                                  PATIENT & MEMBER EXPERIENCE
                                </span>
                            <span className="block text-body-color">
                                  END-TO-END JOURNEY
                                </span>
                            <span className="block text-body-color">
                                  PHYSICIAN & TEAM MEMBER ENGAGEMENT
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pb-12 bg-[#f6f9ff] dark:bg-dark lg:pb-[0px] lg:pt-[120px]">
                <div className="container mx-auto">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-3/4 px-4">
                            <div className="mx-auto mb-12 text-left lg:mb-20">
                                  <span className="mb-2 block text-lg font-semibold text-primary">
                                    Our Services
                                  </span>
                                <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                                    Simplify Your Workflow, Maximize Results
                                </h2>
                                <p className="text-base text-body-color dark:text-dark-6">
                                    We are a leading provider of cloud-based Electronic Health Record (EHR) solutions
                                    designed to streamline healthcare operations and improve service delivery. Our
                                    mission is to empower healthcare providers with innovative tools that enhance
                                    efficiency, accuracy, and patient satisfaction
                                </p>
                            </div>
                        </div>
                        <div className={'w-1/4'}></div>
                    </div>

                    <div
                        className="grid grid-cols-1 text-center sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 text-body-color">
                        <div className="md:p-8 lg:p-8">
                            <h3 className="mt-1 text-xl font-bold text-gray-900 font-pj">Improved Patient
                                management</h3>
                            <p className="mt-5 text-base text-body-color font-pj">Quick access to comprehensive patient
                                information empowers healthcare providers to make informed decisions & deliver optimal
                                care.</p>
                        </div>

                        <div className="md:p-8 lg:p-8 md:border-l md:border-gray-200">
                            <h3 className="mt-1 text-xl font-bold text-gray-900 font-pj">Enhanced Communication</h3>
                            <p className="mt-5 text-base text-body-color font-pj">Easily share patient data among care
                                providers for better coordination and continuity of care.</p>
                        </div>

                        <div className="md:p-8 lg:p-8 md:border-l md:border-gray-200">
                            <h3 className="mt-1 text-xl font-bold text-gray-900 font-pj">Reduced Errors</h3>
                            <p className="mt-5 text-base text-body-color font-pj">Accurate and up-to-date records
                                minimize
                                the risk of medication errors</p>
                        </div>

                        <div className="md:p-8 lg:p-8 md:border-t md:border-gray-200">
                            <h3 className="mt-1 text-xl font-bold text-gray-900 font-pj">Streamlined Workflows</h3>
                            <p className="mt-5 text-base text-body-color font-pj">Efficiently manage patient
                                information,
                                saving time and improving productivity</p>
                        </div>

                        <div className="md:p-8 lg:p-8 md:border-l md:border-gray-200 md:border-t">
                            <h3 className="mt-1 text-xl font-bold text-gray-900 font-pj">Improved Patient Care</h3>
                            <p className="mt-5 text-base text-body-color font-pj">Accurate and complete documentation
                                supports better decision-making and patient outcomes.</p>
                        </div>
                        <div className="md:p-8 lg:p-8 md:border-l md:border-gray-200 md:border-t">
                            <h3 className="mt-1 text-xl font-bold text-gray-900 font-pj">Increased Efficiency</h3>
                            <p className="mt-5 text-base text-body-color font-pj">Save time and reduce documentation
                                errors with streamlined workflows.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="grid grid-cols-1 lg:grid-cols-8 lg:gap-x-16 xl:gap-x-24 gap-y-12">
                        <div className="lg:col-span-5 xl:pr-24">
                            <div className="max-w-lg lg:max-w-none">
                                <p className="text-base font-semibold text-gray-500">PATIENT & MEMBER EXPERIENCE</p>
                                <h2 className="mt-6 text-1xl tracking-tight text-dark lg:mt-8 sm:text-4xl lg:text-4xl">Humanize
                                    experiences with compassionate listening in real time</h2>
                                <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:pr-24 lg:mt-6 lg:leading-8">
                                    Get the patient and member insights needed to create personalized experiences at scale.
                                </p>
                            </div>

                            <div
                                className="grid grid-cols-1 pt-8 mt-8 border-t border-gray-200 sm:grid-cols-2 gap-x-16 gap-y-8">
                                <h3 className="text-[14px] font-medium text-body-color">Deliver seamless, tailored experiences across all touchpoints with real-time feedback capture and actionable insights.</h3>

                                <h3 className="text-[14px] font-medium text-body-color">Know how, when, and where to make improvements by integrating experience signals with operational data</h3>
                            </div>

                            <div className="mt-12">
                                <a href="#" title=""
                                   className="inline-flex items-center text-sm font-semibold text-blue-600 transition-all duration-200 group hover:text-blue-800 hover:underline">
                                    Learn More
                                </a>
                            </div>
                        </div>

                        <div className="lg:col-span-3">
                            <Doctor />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-20 lg:py-[120px] bg-white dark:bg-dark">
                <div className="container mx-auto">
                    <div className="relative z-10 overflow-hidden rounded bg-primary py-12 px-8 md:p-[70px]">
                        <div className="flex flex-wrap items-center -mx-4">
                            <div className="w-full px-4 lg:w-5/6">
                                <span className="block mb-4 text-base font-medium text-white">
                                  Providing care can be simpler
                                </span>
                                <h2 className="mb-6 text-[18px]  leading-tight text-white sm:mb-8 sm:text-[40px] lg:mb-0">
                                    <span className="xs:block"> Transform your ambulatory practice’s performance with a solution that includes EHR, practice management, and patient engagement capabilities and services.</span>
                                </h2>
                            </div>
                            <div className="w-full px-4 lg:w-1/6">
                                <div className="flex flex-wrap lg:justify-end">
                                    <a
                                        href="javascript:void(0)"
                                        className="inline-flex py-2 my-1 text-base font-medium text-white transition rounded-md bg-secondary px-4 hover:bg-opacity-90"
                                    >
                                        Schedule a Demo
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
              <span className="absolute top-0 left-0 z-[-1]">
                <svg
                    width="189"
                    height="162"
                    viewBox="0 0 189 162"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                      cx="16"
                      cy="-16.5"
                      rx="173"
                      ry="178.5"
                      transform="rotate(180 16 -16.5)"
                      fill="url(#paint0_linear)"
                  />
                  <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="-157"
                        y1="-107.754"
                        x2="98.5011"
                        y2="-106.425"
                        gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" stop-opacity="0.07"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
              </span>
                            <span className="absolute bottom-0 right-0 z-[-1]">
                <svg
                    width="191"
                    height="208"
                    viewBox="0 0 191 208"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                      cx="173"
                      cy="178.5"
                      rx="173"
                      ry="178.5"
                      fill="url(#paint0_linear)"
                  />
                  <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="-3.27832e-05"
                        y1="87.2457"
                        x2="255.501"
                        y2="88.5747"
                        gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" stop-opacity="0.07"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
              </span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-12 bg-white sm:py-16 lg:py-20">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 flex">
                    <div className={'w-2/5'}>
                        <Doctors/>
                    </div>
                    <div className="text-left w-3/5">
                        <h2 className=" font-bold leading-tight sm:text-4xl xl:text-5xl text-dark">
                            Lead healthcare transformation with innovative technology
                        </h2>
                        <p className="mt-4 text-base text-body-color leading-7 sm:mt-8 font-pj">
                            Essence Health is a leading provider of cloud-based Electronic Health Record (EHR) solutions
                            designed to streamline healthcare operations and improve service delivery. Our mission is to
                            empower healthcare providers with innovative tools that enhance efficiency, accuracy, and
                            patient satisfaction. Our Vision To be the preferred partner for healthcare providers,
                            offering cutting-edge technology solutions that drive industry transformation.
                        </p>
                    </div>
                </div>
            </section>
            <footer>
                <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                        <div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
                            <div className="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
                                <img className="w-auto h-9"
                                     src={Logo} alt="Essence"/>
                                <p className="text-base leading-relaxed text-gray-600 mt-7">Copyright © 2024 . Essence
                                    Inc. All rights reserved.</p>
                            </div>

                            <div>
                                <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">Company</p>

                                <ul className="mt-6 space-y-4">
                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> About </a>
                                    </li>

                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Features </a>
                                    </li>

                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Career </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">Help</p>

                                <ul className="mt-6 space-y-4">
                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Customer
                                            Support </a>
                                    </li>

                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Our
                                            Services </a>
                                    </li>

                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Terms
                                            & Conditions </a>
                                    </li>

                                    <li>
                                        <a href="#" title=""
                                           className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Privacy
                                            Policy </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
                                <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">Subscribe
                                    to newsletter</p>

                                <form action="#" method="POST" className="mt-6">
                                    <div>
                                        <label htmlFor="email" className="sr-only">Email</label>
                                        <input type="email" name="email" id="email" placeholder="Enter your email"
                                               className="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"/>
                                    </div>

                                    <button type="submit"
                                            className="inline-flex items-center justify-center px-6 py-4 mt-3 font-semibold text-white transition-all duration-200 bg-blue-600 rounded-md hover:bg-blue-700 focus:bg-blue-700">Subscribe
                                    </button>
                                </form>
                            </div>
                        </div>

                        <hr className="mt-16 mb-10 border-gray-200"/>

                        <p className="text-sm text-center text-gray-600">© Copyright 2024, All Rights Reserved by
                            Essence</p>
                    </div>
                </section>

            </footer>
        </div>
    );
};

export default Home;

const LinkGroup = ({children, header}) => {
    return (
        <>
            <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
                <div className="mb-10 w-full">
                    <h4 className="mb-9 text-lg font-semibold text-dark dark:text-white">
                        {header}
                    </h4>
                    <ul className="space-y-3">{children}</ul>
                </div>
            </div>
        </>
    );
};

const NavLink = ({link, label}) => {
    return (
        <li>
            <a
                href={link}
                className="inline-block text-base leading-loose text-body-color hover:text-primary dark:text-dark-6"
            >
                {label}
            </a>
        </li>
    );
};
